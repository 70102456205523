export default {

    // ABOUT US
    titleAboutUs: 'Про нас',

    // CONTACTS
    titleContacts: 'Контакти магазинів',
    ogDescriptionPrefix: 'Інтернет-магазин adidas в Україні',

    titleSizeGuide: 'Таблиця розмірів',

    schema: {
        description: "Офіційний сайт adidas в Україні ✩ Найкращі спортивні товари ✩ Доставка в усі міста країни",
        addressLocality: 'Київ',
        streetAddress: 'вул. Гарматна, 4',
    },

    // FAQ
    faq: {
        metaHelp: {
            title: 'Найпопулярніші питання — Дізнайтеся про все на adidas',
            meta: [
                {
                    content: "Найпопулярніші питання ☆ Умови повернення ☆ Як стати учасником adidas Universe? ☆ Що таке купон і як його застосувати? ☆ Знайдіть відповіді на всі питання на adidas",
                    name: "description"
                },
                {
                    content: "Найпопулярніші питання ☆ Умови повернення ☆ Як стати учасником adidas Universe? ☆ Що таке купон і як його застосувати? ☆ Знайдіть відповіді на всі питання на adidas",
                    name: "og:description"
                },
            ]
        },
        popular: {
            title: 'Найпопулярніші питання',
            q_1: 'Як відмінити замовлення?',
            q_2: 'Контакти',
            q_3: 'Доставка курьерськими службами',
            q_4: 'Доставка та повернення товару',
            q_5: 'Доставка ',
            q_6: 'Як скористатися знижкою adidas Universe на сайті?'
        },
        themes: {
            title: 'Теми питань'
        },
        myOrder: {
            title: 'Де моє замовлення',
            subtitle: 'Ти завжди можеш дізнатися статус свого замовлення в особистому кабінеті або в розділі «Відстежити замовлення».',
            buttonEnter: 'Увійти',
            buttonTracking: 'Відстежити замовлення'
        },
        noQuestion: {
            title: 'Не знайшли відповідь?',
            subtitle: 'Служба підтримки готова допомогти тобі з {from} до {to}, без вихідних.',
            phone: 'за номером: 0 800 21 22 32;',
            email: 'на email <a href="mailto:support@ua.adidas.com" target="_blank">support@ua.adidas.com</a>;',
            message: 'Чат на сайті adidas.ua, іконка чату доступна на кожній сторінці сайту.<br>' +
                'Надіслати повідомлення можна через діалогове вікно чату або у месенджері Telegram.'
        }
    },

    orderTracker: {
        title: 'Відстежити замовлення',
        text1: 'Введіть номер Вашого замовлення і електронну адресу, вказану при його оформленні.',
        errorTitle: 'Неправильна комбінація',
        errorText: 'Неправильна електронна адреса або номер замовлення.',
        infoTitle: 'Що це?',
        infoText: 'Номер замовлення вказано в листі з підтвердженням замовлення. Не отримав лист? Він міг потрапити у папку «Спам». Якщо замовлення було зроблено нещодавно час його обробки може становити до 5 хвилин.',
        btnText: 'Статус замовлення',
        btnText2: 'Увійти в аккаунт',
        text2: 'Не можеш знайти номер замовлення? Увійди до аккаунту, щоб переглянути історію замовлень',
    },

    // PRODUCT INFORMATION
    titleProductInformation: 'Інформація про товар',

    // PRIVACY POLICY
    titlePrivacyPolicy: 'Політика конфіденційності',

    // CHECKOUT INFO
    titleCheckoutInfo: 'Оформлення замовлення',

    // DELIVERY
    titleDelivery: 'Доставка',

    // RETURN AND REFUND
    titleReturnRefund: 'Повернення та обмін',

    // PAYMENT TYPES
    titlePaymentTypes: 'Варіанти оплати',

    // COUPONS AND SALES
    titleCouponsSales: 'Купони и акції',

    // ACCOUNT AND SUBSCRIBE
    titleAccountSubscribe: 'Особистий кабінет та підписка на новини',

    // PUBLIC OFFERT
    titlePublicOffert: 'Публична оферта',

    // GDPR
    titleGDPR: 'Загальні правила збору персональний даних',

    // LOYALTY PROGRAM
    titleLoyaltyProgram: 'Програма лояльності',

    days: {
        monday: 'Понеділок',
        tuesday: 'Вівторок',
        wednesday: 'Середа',
        thursday: 'Четвер',
        friday: 'П\'ятниця',
        saturday: 'Субота',
        sunday: 'Неділя',
    },

    storeFinder: {
        title: 'Пошук магазину',
        searchPlaceholder: 'КРАЇНА, МІСТО, РЕГІОН, ІНДЕКС',
        findNear: 'Знайти магазини поряд',
        phoneTitle: 'Телефон',
        addressTitle: 'Адреса',
        scheduleTitle: 'Години роботи',
        destinationAfter: 'км. від тебе',
        loadMore: 'Завантажити ще',
        from: 'з',
        to: 'до',
    }
}
