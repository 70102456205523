//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LiveChatWidget } from '@livechat/widget-vue/v2';
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "LiveChat",
    components: {
        LiveChatWidget,
    },
    data() {
        return {
            showMenu: false
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        },
        ifRuLang() {
            return this.$i18n.locale === 'ru';
        }
    },
    methods: {
        handleVisibility(event) {
            if (event.visibility !== "maximized") {
                setTimeout(function (){
                    this.body.classList.remove("supportOpened");
                }.bind(this), 200)
            } else {
                this.body.classList.add("supportOpened");
            }
        },
        toggleMenu(flag, time = 0) {
            setTimeout(()=>{
                this.showMenu = flag
            }, time)
        },
        clickChatBtn() {
            if (this.tabletLandscapeUp) {
                this.showChat();
            } else {
                this.toggleMenu(!this.showMenu, 0);
            }
        },
        showChat() {
            this.body.classList.add("supportOpened");
            this.maximizeChat();
        },
        chatCallMethod(methodName) {
            if (window.LiveChatWidget) {
                window.LiveChatWidget.call(methodName);
            }
        },
        hideChat() {
            this.body.classList.remove("supportOpened");
            this.chatCallMethod("destroy");
        },
        maximizeChat() {
            this.chatCallMethod("maximize");
        },
    },

    mounted() {
        if (this.body && this.body.classList.contains("v-popup-open")) {
            this.hideChat()
        }
    }

}
