export default {

    // ABOUT US
    titleAboutUs: 'Про нас',

    // CONTACTS
    titleContacts: 'Контакты магазинов',
    ogDescriptionPrefix: 'Интернет-магазин adidas в Украине',

    titleSizeGuide: 'Таблица размеров',

    schema: {
        description: "Официальный сайт adidas в Украине ✩ Лучшие спортивные товары ✩ Доставка во все города страны",
        addressLocality: 'Киев',
        streetAddress: 'ул. Гарматная, 4',
    },

    // FAQ
    faq: {
        metaHelp: {
            title: 'Часто задаваемые вопросы — Узнавайте обо всем на adidas',
            meta: [
                {
                    content: "Часто задаваемые вопросы ☆ Условия возврата ☆ Как стать участником adidas Universe? ☆ Что такое купон и как его применить? ☆ Найдите ответы на все вопросы на adidas",
                    name: "description"
                },
                {
                    content: "Часто задаваемые вопросы ☆ Условия возврата ☆ Как стать участником adidas Universe? ☆ Что такое купон и как его применить? ☆ Найдите ответы на все вопросы на adidas",
                    name: "og:description"
                },
            ]
        },
        meta: {
            main: {
                title: 'Часто задаваемые вопросы - adidas',
                meta: [
                    {
                        content: "Найпопулярніші питання - adidas",
                        name: "description"
                    }
                ]
            },
            mainWithQueryParams: {
                title: 'Часто задаваемые вопросы - adidas',
                meta: [
                    {
                        content: "noindex,nofollow",
                        name: "robots"
                    }
                ]
            }
        },
        popular: {
            title: 'Часто задаваемые вопросы',
            q_1: 'Как отменить заказ?',
            q_2: 'Контакты',
            q_3: 'Доставка курьерскими службами',
            q_4: 'Доставка и возврат товара',
            q_5: 'Доставка',
            q_6: 'Как пользоваться скидкой adidas Universe на сайте?'
        },
        themes: {
            title: 'Темы вопросов'
        },
        myOrder: {
            title: 'Где мой заказ',
            subtitle: 'Ты всегда можешь узнать статус своего заказа в личном кабинете или в разделе «Отследить заказ».',
            buttonEnter: 'Войти',
            buttonTracking: 'Отследить заказ'
        },
        noQuestion: {
            title: 'Не нашли ответ?',
            subtitle: 'Служба поддержки готова помочь тебе с {from} до {to}, без выходных.',
            phone: 'по номеру: 0 800 21 22 32',
            email: 'на e-mail <a href="mailto:support@ua.adidas.com" target="_blank">support@ua.adidas.com</a>;',
            message: 'Чат на сайте adidas.ua, иконка чата доступна на каждой странице сайта.<br>' +
                'Отправить сообщение можно через диалоговое окно чата или в мессенджере Telegram.'
        }
    },

    orderTracker: {
        title: 'Отследить заказ',
        text1: 'Введите номер Вашего заказа и электронный адрес, указанный при его оформлении.',
        errorTitle: 'Неверная комбинация',
        errorText: 'Неправильный адрес электронной почты или номер заказа.',
        infoTitle: 'Что это?',
        infoText: 'Номер заказа указан в письме с подтверждением заказа. Не получил письмо? Оно могло попасть в папку «Спам». Если заказ был сделан недавно время его обработки может занимать до 5 минут.',
        btnText: 'Статус заказа',
        btnText2: 'Войти в аккаунт',
        text2: 'Не можешь найти номер заказа? Войди в аккаунт, чтобы просмотреть всю историю заказов',

    },

    // PRODUCT INFORMATION
    titleProductInformation: 'Информация про товар',

    // PRIVACY POLICY
    titlePrivacyPolicy: 'Политика конфеденциальности',

    // CHECKOUT INFO
    titleCheckoutInfo: 'Оформление заказа',

    // DELIVERY
    titleDelivery: 'Доставка',

    // RETURN AND REFUND
    titleReturnRefund: 'Возврат и обмен',

    // PAYMENT TYPES
    titlePaymentTypes: 'Способы оплаты',

    // COUPONS AND SALES
    titleCouponsSales: 'Купоны и акции',

    // ACCOUNT AND SUBSCRIBE
    titleAccountSubscribe: 'Личный кабинет и подписка на новости',

    // PUBLIC OFFERT
    titlePublicOffert: 'Публичная оферта',

    // GDPR
    titleGDPR: 'Общие правила сбора персональных данных',

    // LOYALTY PROGRAM
    titleLoyaltyProgram: 'Програма лояльности',

    days: {
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье',
    },

    storeFinder: {
        title: 'Поиск магазина',
        searchPlaceholder: 'СТРАНА, ГОРОД, РЕГИОН, ИНДЕКС',
        findNear: 'Найти магазины рядом',
        phoneTitle: 'Телефон',
        addressTitle: 'Адрес',
        scheduleTitle: 'Время работы',
        destinationAfter: 'км. от тебя',
        loadMore: 'Загрузить еще',
        from: 'с',
        to: 'до',

    }
}
